<template>
  <div ref="byAge">
    <div style="height: 80px"></div>
    <v-container >
      <v-flex d-flex justify-center mt-auto>
<!--        <h5><b>나이별 다양성 분석</b></h5>-->
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="byAgeDiversity" :init-options="chartSize"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex d-flex justify-center mt-auto>
<!--        Absence Ratio: {{ byAgeDiversity.absenceRatio || 0}}% {{byAgeDiversity.absenceZeroCnt}}{{ byAgeDiversity.absenceTotalCnt}}-->
      </v-flex>
    </v-layout>
    <div style="height: 50px">
    </div>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="freqDistributionListChart" :init-options="chartSize"/>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

export default {
  props: {
    data : Object,
    code : String
  },
  data() {
    return {
      chartSize : { width: 1016, height: 500 }
    }
  },
  methods: {
    chartResize() {
      try {
        let resizeWidth = this.$refs.byAge.clientWidth;
        this.chartSize.init.width = resizeWidth;
      } catch (e) {
        console.log("resize", e);
      }
    }
  },
  mounted () {
    this.chartResize();
  },
  computed : {
    byAgeDiversity() {
      let byAgeChart = this.$store.state.byAgeDiversity;
      if(typeof(byAgeChart.tooltip) === 'object'){
        byAgeChart.tooltip.formatter = (p, a, b) => {
          console.log(p , a , b)
          return `Value&nbsp;: ${p.data[1].toFixed(2)}<br />
                  SampleId&nbsp;: ${p.data[2]}<br />
                  Age&nbsp;: ${p.data[0]}` ;
        }
        // 이벤트를 2개 넣을 수 없음 , 그렇기 때문에 클릭 이벤트 제거
        byAgeChart.tooltip.triggerOn = "mousemove";
        byAgeChart.selectedMode = "single";
      }
      return byAgeChart;
    },
    freqDistributionListChart() {
      return this.$store.state.freqDistributionListChart;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../theme/reportScss';
</style>